<template>
  <a-result
      status="success"
      title="签到成功"
  >
    <template #extra>
      <a-button key="console" type="primary" @click="closeW">
        关闭此页面
      </a-button>
    </template>

    <div class="desc">
      <p style="font-size: 16px;">
        <strong>时间:</strong>
      </p>
      <p>
        {{ time }}
      </p>
      <p style="font-size: 16px;">
        <strong>学工号:</strong>
      </p>
      <p>
        {{ userid }}
      </p>
    </div>
  </a-result>
</template>

<script>
import {getJssdkConfig} from "../api/manage";

// eslint-disable-next-line no-undef
wx.ready(function () {
  // eslint-disable-next-line no-undef
  wx.hideOptionMenu();
});
export default {
  data() {
    return {
      time: '',
      userid: ''
    }
  },
  mounted() {
    this.userid = this.$route.query.tid
    this.time = this.timestampToStr(this.$route.query.time)
    getJssdkConfig({url: window.location.href}).then(res => {
      // eslint-disable-next-line no-undef
      wx.config({
        beta: true,
        debug: false,
        appId: res.config.appId,
        timestamp: res.config.timestamp,
        nonceStr: res.config.nonceStr,
        signature: res.config.signature,
        jsApiList: ['hideOptionMenu', 'closeWindow']
      })
    })
  },
  methods: {
    closeW() {
      // eslint-disable-next-line no-undef
      wx.closeWindow();
    },
    timestampToStr(te) {
      te = parseInt(te)
      if(te === ''){
        return '';
      }else if(te.length === 10){
        let time = new Date(te * 1000); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
        let y = time.getFullYear();
        let m = time.getMonth() < 9 ? '0' + (time.getMonth() + 1) : time.getMonth() + 1;
        let d = time.getDate() < 10 ? '0' + time.getDate() : time.getDate();
        let h = time.getHours() < 10 ? '0' + time.getHours() : time.getHours();
        let mm = time.getMinutes() < 10 ? '0' + time.getMinutes() : time.getMinutes();
        let s = time.getSeconds() < 10 ? '0' + time.getSeconds() : time.getSeconds();
        return y + '-' + m + '-' + d + ' ' + h + ':' + mm + ':' + s;
      }else{
        let time = new Date(te);
        let y = time.getFullYear();
        let m = time.getMonth() < 9 ? '0' + (time.getMonth() + 1) : time.getMonth() + 1;
        let d = time.getDate() < 10 ? '0' + time.getDate() : time.getDate();
        let h = time.getHours() < 10 ? '0' + time.getHours() : time.getHours();
        let mm = time.getMinutes() < 10 ? '0' + time.getMinutes() : time.getMinutes();
        let s = time.getSeconds() < 10 ? '0' + time.getSeconds() : time.getSeconds();
        return y + '-' + m + '-' + d + ' ' + h + ':' + mm + ':' + s;
      }
    }
  }
}
</script>
<style scoped>
.desc p {
  margin-bottom: 1em;
}
</style>
