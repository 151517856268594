import { axios } from '@/utils/request'

const domain = process.env.NODE_ENV === 'production' ? 'https://fk.bitzh.edu.cn' : 'http://127.0.0.1:8080'

const api = {
  info: domain + '/api/v2/mobile/getInfo',
  insertRecord: domain + '/api/v2/mobile/insertRecord',
  getRecord: 'https://hb.zhbit.com/ncov/getRecord',
  login: domain + '/api/v2/mobile/getPassCodeByIdAndName',
  getInfoList: 'https://hb.zhbit.com/ncov/getInfoList',
  handleInfo: 'https://hb.zhbit.com/ncov/handleInfo',
  getJssdkConfig: domain + '/api/v2/mobile/getJssdkConfig',
  getInfoByToken: domain + '/api/v2/mobile/getInfoByToken',
  sign: domain + '/api/v2/mobile/sign',
  getScanResult: domain + '/api/v2/mobile/getScanResult'

  // login: 'http://127.0.0.1:8080/zhbit_ncov_java_war_exploded/login',
  // info: 'http://127.0.0.1:8080/zhbit_ncov_java_war_exploded/getInfo',
  // insertRecord: 'http://127.0.0.1:8080/zhbit_ncov_java_war_exploded/insertRecord',
  // getRecord: 'http://127.0.0.1:8080/zhbit_ncov_java_war_exploded/getRecord',
  // getInfoList: 'http://127.0.0.1:8080/zhbit_ncov_java_war_exploded/getInfoList',
  // handleInfo: 'http://127.0.0.1:8080/zhbit_ncov_java_war_exploded/handleInfo'
  // getJssdkConfig: 'http://127.0.0.1:8080/zhbit_ncov_java_war_exploded/getJssdkConfig'
}

export default api

export function getInfo (parameter) {
  return axios({
    url: api.info,
    method: 'post',
    params: parameter
  })
}

export function insertRecord (parameter) {
  return axios({
    url: api.insertRecord,
    method: 'post',
    params: parameter
  })
}

export function getRecord (parameter) {
  return axios({
    url: api.getRecord,
    method: 'post',
    params: parameter
  })
}

export function getInfos (parameter) {
  return axios({
    url: api.getInfoList,
    method: 'post',
    params: parameter
  })
}

export function login (parameter) {
  return axios({
    url: api.login,
    method: 'post',
    data: parameter
  })
}

export function handleInfo (parameter) {
  return axios({
    url: api.handleInfo,
    method: 'post',
    params: {},
    data: parameter
  })
}

export function getJssdkConfig (parameter) {
  return axios({
    url: api.getJssdkConfig,
    method: 'post',
    params: parameter
  })
}

export function getInfoByToken (parameter) {
  return axios({
    url: api.getInfoByToken,
    method: 'post',
    params: parameter
  })
}

export function sign (parameter) {
  return axios({
    url: api.sign,
    method: 'post',
    params: parameter
  })
}

export function getScanResult (parameter) {
  return axios({
    url: api.getScanResult,
    method: 'post',
    params: parameter
  })
}
