import Vue from 'vue'
import Router from 'vue-router'
import Hello from '../components/HelloWorld'
import MyPassCode from '../components/MyQrcode'
import Login from '../components/Login'
import Error from '../components/Error'
import SignSuccess from "../components/SignSuccess";
import Success from "../components/Success";

Vue.use(Router)

export default new Router({
    routes: [
        {
            path: '/guard',
            name: 'Hello',
            component: Hello,
            meta: {
                title: '扫码放行'
            }
        },
        {
            path: '/myPassCode',
            name: 'myPassCode',
            component: MyPassCode,
            meta: {
                title: '北理珠通行码'
            }
        },
        {
            path: '/notSubscribe',
            name: 'notSubscribe',
            component: Login,
            meta: {
                title: '获取通行码'
            }
        },
        {
            path: '/error',
            name: 'error',
            component: Error,
            meta: {
                title: '错误'
            }
        },
        {
            path: '/signSuccess',
            name: 'signSuccess',
            component: SignSuccess,
            meta: {
                title: '成功'
            }
        },
        {
            path: '/questionSuccess',
            name: 'questionSuccess',
            component: Success,
            meta: {
                title: '成功'
            }
        }
    ]
})
