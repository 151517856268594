<template>
  <a-result
      status="success"
      title="问卷填写完成"
      sub-title="请关闭此页面"
  >
    <template #extra>
      <a-button key="console" type="primary" @click="closeW">
        关闭此页面
      </a-button>
    </template>
  </a-result>
</template>

<script>
import {getJssdkConfig} from "../api/manage";

// eslint-disable-next-line no-undef
wx.ready(function () {
  // eslint-disable-next-line no-undef
  wx.hideOptionMenu();
});
export default {
  data() {
    return {}
  },
  mounted() {
    getJssdkConfig({url: window.location.href}).then(res => {
      // eslint-disable-next-line no-undef
      wx.config({
        beta: true,
        debug: false,
        appId: res.config.appId,
        timestamp: res.config.timestamp,
        nonceStr: res.config.nonceStr,
        signature: res.config.signature,
        jsApiList: ['hideOptionMenu', 'closeWindow']
      })
    })
  },
  methods: {
    closeW() {
      // eslint-disable-next-line no-undef
      wx.closeWindow();
    }
  }
}
</script>
<style scoped>
.desc p {
  margin-bottom: 1em;
}
</style>
