<template>
  <div class="bg">
    <a-form
        id="formLogin"
        class="user-layout-login"
        ref="formLogin"
        :form="form"
        @submit="handleSubmit"
    >
      <a-alert v-if="isLoginError" type="error" showIcon style="margin-bottom: 24px;" :message="tip"/>
      <a-form-item>
        <a-input
            size="large"
            type="text"
            placeholder="请在这里输入姓名"
            v-decorator="[
          'name',
          { rules: [{ required: true, message: '姓名不能为空!' }] },
        ]"
        >
        </a-input>
      </a-form-item>

      <a-form-item>
        <a-input
            size="large"
            type="text"
            autocomplete="false"
            placeholder="请在这里输入身份证号"
            v-decorator="[
          'idcard',
          { rules: [{ required: true, message: '身份证号不能为空!' }] },
        ]"
        >
        </a-input>
      </a-form-item>

      <a-form-item style="margin-top:24px">
        <a-button
            size="large"
            type="primary"
            htmlType="submit"
            class="login-button"
            :loading="loginBtn"
            :disabled="loginBtn"
        >确定
        </a-button>
      </a-form-item>
    </a-form>
  </div>
</template>

<script>
import {login} from '../api/manage'

export default {
  data() {
    return {
      loginBtn: false,
      isLoginError: false,
      form: this.$form.createForm(this),
      tip: ''
    }
  },
  methods: {
    handleSubmit(e) {
      e.preventDefault()
      const {
        form: {validateFields}
      } = this

      this.loginBtn = true

      const validateFieldsKey = ['name', 'idcard']

      validateFields(validateFieldsKey, {force: true}, (err, values) => {
        if (!err) {
          const loginParams = {...values}
          login({ id: loginParams.idcard, name: loginParams.name })
              .then((res) => {
                if (res.code === 200) {
                  this.loginSuccess(res)
                } else {
                  this.requestFailed(res)
                }
              })
              .catch(err => this.requestFailed(err))
              .finally(() => {
                this.loginBtn = false
              })
        } else {
          setTimeout(() => {
            this.loginBtn = false
          }, 600)
        }
      })
    },
    loginSuccess (res) {
      this.$router.push('myPassCode?token=' + res.token)
    },
    requestFailed (err) {
      this.isLoginError = true
      this.tip = err.msg
    }
  }
}
</script>

<style lang="less" scoped>
#formLogin{
  margin-top: 100px;
}
.bg {
  max-width: 368px;
  width: 98%;
  min-width: 260px;
  margin: 0 auto;
}

.user-layout-login {
  label {
    font-size: 14px;
  }

  .getCaptcha {
    display: block;
    width: 100%;
    height: 40px;
  }

  .forge-password {
    font-size: 14px;
  }

  button.login-button {
    padding: 0 15px;
    font-size: 16px;
    height: 40px;
    width: 100%;
  }

  .user-login-other {
    text-align: left;
    margin-top: 24px;
    line-height: 22px;

    .item-icon {
      font-size: 24px;
      color: rgba(0, 0, 0, 0.2);
      margin-left: 16px;
      vertical-align: middle;
      cursor: pointer;
      transition: color 0.3s;

      &:hover {
        color: #1890ff;
      }
    }

    .register {
      float: right;
    }
  }
}
</style>
