<template>
  <div class="bg">
    <a-form-item
        :labelCol="labelCol"
        :wrapperCol="wrapperCol"
        label="工号/姓名"
        v-if="showInput"
    >
      <a-input-search
          placeholder="请输入工号或姓名"
          v-model="mdl.userid"
          @search="getInfo(mdl.userid)"
          @pressEnter="getInfo(mdl.userid)"
          enterButton="查询"
      />
    </a-form-item>
    <a-table
        :columns="columns1"
        :dataSource="operation1"
        :pagination="false"
        v-if="multi"
    >
      <span slot="action" slot-scope="text, record">
        <template>
          <a @click="getInfo(record.tid)">选择</a>
        </template>
      </span>
    </a-table>
    <a-card style="margin-top: 24px" :bordered="false" :loading="loadingData">
      <a-descriptions title="用户信息">
        <a-descriptions-item label="">
          <span style="color: blue!important;">{{ info.tname }} ({{ info.tid }}, {{ info.peopletype }}, {{ info.XDEPARTMENT }})</span>
        </a-descriptions-item>
        <a-descriptions-item v-if="info.tidcard !== null">
          <span style="color: blue!important;">{{ info.tidcard }}</span>
        </a-descriptions-item>
        <a-descriptions-item label="校内联系人" v-if="info.xname !== null">
          {{ info.XNAME }} {{ info.XID }} {{ info.XDEPARTMENT }}
        </a-descriptions-item>
        <a-descriptions-item label="备注信息">
          {{ info.remark }}
        </a-descriptions-item>
        <a-descriptions-item label="是否允许入校">
          <a style="color: white;padding:4px 10px;border-radius: 4px; background-color: #f50" v-if="info.allowin === '不允许'">不允许入校</a>
          <a style="color: white;padding:4px 10px;border-radius: 4px; background-color: #87d068" v-if="info.allowin === '允许'">允许入校</a>
        </a-descriptions-item>
        <a-descriptions-item label="是否允许出校">
          <a style="color: white;padding:4px 10px;border-radius: 4px; background-color: #f50" v-if="info.allowout === '不允许'">不允许出校</a>
          <a style="color: white;padding:4px 10px;border-radius: 4px; background-color: #87d068" v-if="info.allowout === '允许'">允许出校</a>
        </a-descriptions-item>
      </a-descriptions>
      <a-card type="inner">
        <a-form :form="form">
          <a-form-item
              :labelCol="labelCol"
              :wrapperCol="wrapperCol"
              label="体温"
          >
            <a-input-number
                :min="35"
                :max="42"
                placeholder="测量体温"
                v-model="mdl.temperature"
            />
            <a-button-group>
              <a-button @click="()=>{ mdl.temperature = 35.5 }">35.5</a-button>
              <a-button @click="()=>{ mdl.temperature = 36 }">36</a-button>
              <a-button @click="()=>{ mdl.temperature = 36.5 }">36.5</a-button>
              <a-button @click="()=>{ mdl.temperature = 37 }">37</a-button>
              <a-button @click="()=>{ mdl.temperature = 37.5 }">37.5</a-button>
              <a-button @click="()=>{ mdl.temperature = 38 }">38</a-button>
            </a-button-group>
          </a-form-item>

          <a-form-item
              :labelCol="labelCol"
              :wrapperCol="wrapperCol"
              label="出入方式"
          >
            <a-radio-group defaultValue="走路" buttonStyle="solid" v-model="mdl.type">
              <a-radio-button value="走路">走路</a-radio-button>
              <a-radio-button value="骑车">骑车</a-radio-button>
              <a-radio-button value="开车">开车</a-radio-button>
            </a-radio-group>
          </a-form-item>

          <a-form-item
              :labelCol="labelCol"
              :wrapperCol="wrapperCol"
              label="车牌"
              v-if="mdl.type === '开车'"
          >
            <a-input placeholder="车牌" v-model="mdl.number" id="number" />
          </a-form-item>

          <a-form-item
              :labelCol="labelCol"
              :wrapperCol="wrapperCol"
              label=""
              v-if="mdl.type === '开车'"
          >
            <a-row>
              <a-col :labelCol="labelCol" :wrapperCol="wrapperCol" v-for="item in cars" :key="item">
                <a style="font-size: 36px;margin-right: 15px" v-if="mdl.number === ''" @click="changeCar(item)">{{ item }}</a>
              </a-col>
            </a-row>
            <a style="font-size: 36px" v-if="mdl.number !== ''">{{ mdl.number }}</a>
          </a-form-item>

          <a-form-item
              :labelCol="labelCol"
              :wrapperCol="wrapperCol"
              label="备注"
          >
            <a-input placeholder="备注" v-model="mdl.remark" id="remark" />
          </a-form-item>

          <a-form-item
              :labelCol="labelCol"
              :wrapperCol="wrapperCol"
              label="最近14天最高体温"
          >
            <div>
              <span style="color:red" v-if="highestTemperature >= 37.3">{{ highestTemperature }}</span>
              <span style="color:green" v-else>{{ highestTemperature }}</span>
            </div>
            <div>
              <span style="color: green" v-if="healthe" @click="()=>{ showQuestionnaire = !showQuestionnaire }">近14天健康(点击查看详细记录)</span>
              <span style="color: red" v-if="!healthe" @click="()=>{ showQuestionnaire = !showQuestionnaire }">近14天身体异常(点击查看详细记录)</span>
              <a-table
                  :columns="columns3"
                  :dataSource="questionnaire"
                  :pagination="false"
                  :loading="loadingData"
                  v-show="showQuestionnaire"
                  style="margin-top: 10px"
              >
              </a-table>
            </div>
          </a-form-item>

          <a-form-item
              :labelCol="labelCol"
              :wrapperCol="wrapperCol"
              label=""
          >
            <div>
              <a-button class="else2" @click="confirm(1)" :disabled="info.allowin === '不允许'" block>{{ info.allowin === '不允许' ? '不允许入校' : '放行入校' }}</a-button>
            </div>
            <div>
              <a-button class="else" @click="confirm(2)" :disabled="info.allowout === '不允许'" block>{{ info.allowout === '不允许' ? '不允许出校' : '放行出校' }}</a-button>
            </div>
          </a-form-item>
        </a-form>
      </a-card>
    </a-card>
    <a-card style="margin-top: 24px" :bordered="false" title="">
      <a-table
          :columns="columns2"
          :dataSource="operation2"
          :pagination="false"
          :loading="loadingData"
      >
      </a-table>
    </a-card>
    <a-modal title="操作确认" v-model="visible" @ok="confirm2" okText="确认" cancelText="取消">
      <p>姓名: {{ this.info.tname }}</p>
      <p>人员类型: {{ this.info.peopletype }}</p>
      <p>进/出: {{ this.mdl.inout === 1 ? '进校' : '出校' }}</p>
      <p>体温: {{ this.mdl.temperature }}℃</p>
      <p>出行方式: {{ this.mdl.type }}</p>
      <p>备注: {{ this.mdl.remark }}</p>
    </a-modal>
  </div>
</template>

<script>
import { getJssdkConfig, getInfo, insertRecord } from '../api/manage'
import moment from 'moment'
// import Vue from 'vue'
// eslint-disable-next-line no-undef
wx.ready(function(){
  // eslint-disable-next-line no-undef
  wx.hideOptionMenu();
});
export default {
  mounted () {
    // if (!navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)) {
    //   if (typeof this.$route.query.tid !== 'undefined' && typeof this.$route.query.oid !== 'undefined' && typeof this.$route.query.sign !== 'undefined') {
    //     this.$router.push('/dashboard/guard?tid=' + this.$route.query.tid + '&oid=' + this.$route.query.oid + '&sign=' + this.$route.query.sign)
    //   } else {
    //     this.$router.push('/dashboard/guard')
    //   }
    //   return
    // }
    // studentToken && guardToken
    if (typeof this.$route.query.s !== 'undefined' && typeof this.$route.query.g !== 'undefined') {
      this.getInfo(this.$route.query.s, this.$route.query.g)
      this.showInput = false
    }
    getJssdkConfig({ url: window.location.href }).then(res => {
      // eslint-disable-next-line no-undef
      wx.config({
        beta: true,
        debug: false,
        appId: res.config.appId,
        timestamp: res.config.timestamp,
        nonceStr: res.config.nonceStr,
        signature: res.config.signature,
        jsApiList: ['hideOptionMenu', 'closeWindow']
      })
    })
  },
  methods: {
    viewImg: function (e) {
      const s = []
      if (this.suikangma !== '') s.push(this.suikangma)
      if (this.xingchengka !== '') s.push(this.xingchengka)
      if (this.hubeijiankangma !== '') s.push(this.hubeijiankangma)
      // eslint-disable-next-line no-undef
      wx.previewImage({
        current: e, // 当前显示图片的http链接
        urls: s // 需要预览的图片http链接列表
      })
    },
    getInfo: function (sToken, gToken) {
      this.loadingData = true
      const data = {
        s: sToken,
        g: gToken
      }
      getInfo(data).then(res => {
        this.mdl.number = ''
        this.mdl.remark = ''
        this.mdl.temperature = ''
        this.mdl.type = ''
        this.relative = []
        this.suikangma = ''
        this.hubeijiankangma = ''
        this.xingchengka = ''
        if (res.code === 200) {
          if (res.multi && res.multi === 'Y') {
            this.operation1 = res.list
            this.multi = true
          } else {
            this.multi = false
            this.photo = res.photo
            this.info = res.info
            this.relative = res.relative
            this.operation2 = res.record
            this.cars = res.cars
            this.scanToken = res.scanToken
            this.loadingData = false
          }
        } else if (res.code === 101) {
          this.$error({
            title: '错误',
            content: res.msg,
            onOk() {
              // eslint-disable-next-line no-undef
              wx.closeWindow()
            },
          });
        } else if (res.code === 102) {
          this.$error({
            title: '错误',
            content: res.msg,
            onOk() {
              // eslint-disable-next-line no-undef
              wx.closeWindow()
            },
          });
        } else {
          this.$notification.error({
            message: '请求失败',
            description: res.msg
          })
          this.loadingData = true
        }
      })
    },
    confirm2: function () {
      const data = {
        inout: this.mdl.inout,
        temperature: this.mdl.temperature === null ? '' : this.mdl.temperature,
        type: this.mdl.type === '开车' ? this.mdl.type + '(' + this.mdl.number + ')' : this.mdl.type,
        remark: this.mdl.remark,
        scanToken: this.scanToken
      }
      insertRecord(data).then(res => {
        if (res.code === 200) {
          this.$info({
            title: '操作成功',
            content: '放行' + (this.mdl.inout === 1 ? '入校' : '出校') + '成功'
          })

          this.mdl.number = ''
          this.mdl.remark = ''
          this.mdl.temperature = ''
          this.mdl.type = ''
          this.mdl.userid = ''
          this.operation2 = []
          this.relative = []
          this.loadingData = true
          this.visible = false
        } else if (res.code === 101) {
          this.$error({
            title: '错误',
            content: res.msg,
            onOk() {
              console.log(1)
            }
          });
        } else {
          this.$error({
            title: '操作失败',
            content: res.msg,
            onOk() {
              console.log(1)
            }
          })
        }
      }).catch(err => {
        this.$error({
          title: '操作失败',
          content: err.reason || '请求出现错误，请稍后再试',
          duration: 4
        })
      })
    },
    confirm: function (e) {
      this.mdl.inout = e
      this.visible = true
    },
    changeCar: function (e) {
      this.mdl.number = e
    }
  },
  data () {
    return {
      showInput: true,
      multi: false,
      visible: false,
      info: {},
      photo: '',
      loadingData: true,
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      },
      form: this.$form.createForm(this),
      mdl: {
        temperature: '',
        number: '',
        remark: '',
        type: '',
        userid: ''
      },
      columns1: [
        {
          title: '操作',
          dataIndex: 'action',
          width: '150px',
          scopedSlots: { customRender: 'action' }
        },
        {
          title: '工号',
          dataIndex: 'tid',
          key: 'tid'
        },
        {
          title: '姓名',
          dataIndex: 'tname',
          key: 'tname'
        },
        {
          title: '人员类别',
          dataIndex: 'peopletype',
          key: 'peopletype'
        },
        {
          title: '部门',
          dataIndex: 'department',
          key: 'department'
        }
      ],
      operation1: [],
      columns2: [
        {
          title: '出/入',
          dataIndex: 'direction',
          key: 'direction',
          customRender: (text) => {
            return text === 1 ? '入校' : '出校'
          }
        },
        {
          title: '体温',
          dataIndex: 'temperature',
          key: 'temperature'
        },
        {
          title: '出入方式',
          dataIndex: 'type',
          key: 'type'
        },
        {
          title: '时间',
          dataIndex: 'time',
          key: 'time',
          customRender: (text) => {
            return moment(text).format('YYYY-MM-DD HH:mm:ss')
          },
          sorter: (a, b) => moment(a.time).unix() - moment(b.time).unix(),
          defaultSortOrder: 'descend'
        },
        {
          title: '备注',
          dataIndex: 'remark',
          key: 'remark'
        }
      ],
      operation2: [],
      isMobile: false,
      relative: [],
      cars: [],
      highestTemperature: 0,
      questionnaire: [],
      healthe: true,
      columns3: [
        {
          title: '健康状况',
          dataIndex: 'status',
          key: 'status'
        },
        {
          title: '填报日期',
          dataIndex: 'sdate',
          key: 'sdate',
          customRender: (text) => {
            return moment(text).format('YYYY-MM-DD')
          },
          sorter: (a, b) => moment(a.sdate).unix() - moment(b.sdate).unix(),
          defaultSortOrder: 'descend'
        }
      ],
      showQuestionnaire: false,
      suikangma: '',
      xingchengka: '',
      hubeijiankangma: '',
      scanToken: ''
    }
  },
  watch: {
    questionnaire () {
      this.healthe = true
      for (var i of this.questionnaire) {
        if (i.status !== '健康') {
          this.healthe = false
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>
.bg{
  background: #f0f2f5;
  padding: 20px;
  box-sizing: border-box;
}
.detail-layout {
  margin-left: 44px;
}
.text {
  color: rgba(0, 0, 0, .45);
}
.heading {
  color: rgba(0, 0, 0, .85);
  font-size: 20px;
}
.no-data {
  color: rgba(0, 0, 0, .25);
  text-align: center;
  line-height: 64px;
  font-size: 16px;
  i {
    font-size: 24px;
    margin-right: 16px;
    position: relative;
    top: 3px;
  }
}
.mobile {
  .detail-layout {
    margin-left: unset;
  }
  .text {
  }
  .status-list {
    text-align: left;
  }
}
.else{
  background-color: #13c2c2;
  color: white;
}
.else2{
  background-color: #1890ff;
  color: white;
}
</style>
