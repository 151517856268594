<template>
  <div id="bg"
    :style="{background: handleBg}">
    <vue-particles
        v-if="mode !== 'scanQrcode' && ((showIn && info.canIn === 'Y') || (!showIn && info.canOut === 'Y'))"
        :color="info.peopleType === '专升本考生' ? '#00da40' : '#00BFFF'"
        :particleOpacity="0.7"
        :particlesNumber="100"
        shapeType="circle"
        :particleSize="6"
        linesColor="#00BFFF"
        :linesWidth="1"
        :lineLinked="true"
        :lineOpacity="0.4"
        :linesDistance="150"
        :moveSpeed="4"
        :hoverEffect="true"
        hoverMode="grab"
        :clickEffect="true"
        style="z-index: 0"
        clickMode="push">
    </vue-particles>
    <div
        style="display: flex;color:white;font-size: 16px;position: absolute; top:10px;right: 10px;justify-content: center"
        @click="scan" v-if="mode !== 'scanQrcode'">
      <img :src="this.publicPath + 'scan.png'" style="width: 22px;height:22px;margin-right: 2px;"/>
      点击扫码
    </div>
    <div style="margin: 0 auto;padding-top: 10px; text-align: center">
      <img :src="this.publicPath + 'logo2.png'" style="margin-top:20px;height:80px;z-index: 1000;position: relative;" v-if="scanResult.qrcodeType !== 3"/>
      <div style="font-size: 36px;color:green;font-weight: bold" v-if="scanResult.qrcodeType === 3 && scanResult.pass === 'Y'">登记成功</div>
      <div style="font-size: 36px;color:red;font-weight: bold" v-if="scanResult.qrcodeType === 3 && scanResult.pass === 'N'">无权登记</div>
    </div>
    <div id="info">
      <div id="topH">
        {{ dateFormat(currentTime) }}
      </div>
      <div style="display: flex;justify-content: space-around;margin-top: 20px;">
        <div v-if="info.canIn !== 'Y'" style="width: 100%;font-size: 32vw;color: red;border: 3px solid red;border-radius: 20px;">
          <div style="font-size: 22vw;padding: 10px 0;text-align: center">已过期</div>
        </div>
        <div style="text-align: center" v-else>
          <div style="width: 150px;height:150px" ref="qrcode_in" :hidden="!showIn"></div>
<!--          <div style="width: 150px;height:150px" ref="qrcode_out" :hidden="showIn"></div>-->
        </div>
<!--        <div style="text-align: center;align-content: center;"-->
<!--             v-if="!isTeacher || (info.relationList && info.relationList.length > 0)">-->
<!--          <div>-->
<!--            <div v-if="!isTeacher">-->
<!--              <div :class="['direction', info.canIn === 'Y' ? 'allow' : 'notAllow']" v-if="showIn">进码</div>-->
<!--              <div :class="['direction', info.canOut === 'Y' ? 'allow' : 'notAllow']" v-if="!showIn">出码</div>-->
<!--              <div>-->
<!--                <a-switch checked-children="切换出码" un-checked-children="切换进码" v-model="showIn"/>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div v-if="info.relationList && info.relationList.length > 0">-->
<!--              <a-select style="width: 100px;margin-top: 5px" default-value="选择亲属" @change="changeUser">-->
<!--                <a-select-option value="self">-->
<!--                  本人-->
<!--                </a-select-option>-->
<!--                <a-select-option :value="item.id" v-for="(item, index) in info.relationList" v-bind:key="index">-->
<!--                  {{ item.name }}-->
<!--                </a-select-option>-->
<!--              </a-select>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
      </div>
      <div id="tips" v-if="info.unPassReason && info.unPassReason !== ''">{{ info.unPassReason }}</div>


      <div style="height: 1px;border-top: dotted 1px gray;margin-top: 10px;margin-bottom: 10px"></div>
      <div>
        <template v-if="info.peopleType === '新生'">
          <div class="headTitleView">
            <div class="headTitle newStudentColor">{{ info.peopleType }}</div>
          </div>
          <div style="text-align: center" v-if="info.img">
            <img :src="'data:image/jpeg;base64,' + info.img" style="height: 150px;margin: 20px 0;" />
          </div>
          <div style="color: #288c44;font-size: 16px;">
            <div>学工号： {{info.userId}}</div>
            <div style="margin-top: 4px;">姓名： {{info.name}}</div>
            <div style="margin-top: 4px;">身份证： {{info.idcard}}</div>
          </div>
        </template>

        <template v-else-if="info.peopleType === '家属'">
          <div class="headTitleView">
            <div class="headTitle relativeColor">{{ info.peopleType }}</div>
          </div>
          <div style="color: #0690c6;font-size: 15px;text-align: center;margin-top: 16px">
            <span>{{ info.name }}（{{info.idcard}}）</span><span>{{ info.sex }}</span>
          </div>
        </template>

        <template v-else-if="info.peopleType === '教职工家属'">
          <div class="headTitleView">
            <div class="headTitle relative2Color">{{ info.peopleType }}</div>
          </div>
          <div style="color: #0690c6;font-size: 15px;text-align: center;margin-top: 16px">
            <span>{{ info.name }}（{{info.idcard}}）</span><span>{{ info.sex }}</span>
          </div>
        </template>

        <template v-else-if="info.peopleType === '临时人员' || info.peopleType === '校友'">
          <div class="headTitleView">
            <div class="headTitle tempColor">{{ info.peopleType }}</div>
          </div>
          <div style="color: #0d562a;font-size: 15px;margin-top: 20px">
            <div style="margin-top: 4px;margin-left: 10px;">姓名： {{info.name}}</div>
            <div style="margin-top: 4px;margin-left: 10px;">性别： {{info.sex}}</div>
            <div style="margin-top: 4px;margin-left: 10px;">身份证： {{info.idcard}}</div>
          </div>
          <div style="margin-top: 20px; color: red; text-align: center">
            <div>进校时</div>
            <div style="font-weight: bold">请出示 证件 及 本通行码</div>
            <div>以便门卫核对身份</div>
          </div>
          <div style="margin-top: 50px; color: red; text-align: center">
            <div>**如把此通行码转借他人使用，将被学校列为永久<span style="font-weight: bold">黑名单</span></div>
          </div>
        </template>

        <template v-else>
          <div class="headTitleView">
            <div class="headTitle normalColor">{{ info.peopleType }}</div>
          </div>
          <div class="param2">{{
              showRelation ? relation.name : info.name
            }}（{{ showRelation ? relation.idcard : info.idcard }}）
          </div>
          <div class="param2">{{ showRelation ? relation.peopleType : info.peopleType }}<span
              style="margin-left: 10px">{{ info.department }}</span></div>
        </template>
      </div>

<!--      <div v-if="info.peopleType !== '临时人员' && xckInfoUpdateToday && ((info.hsValid && info.hsValid !== '') || (info.hsTime && info.hsTime !== '') || (info.ymNum && info.ymNum !== '') || (info.ymTime && info.ymTime !== '') || (info.xckStar && info.xckStar !== '') || (info.xck && info.xck !== ''))">-->
<!--        <div style="padding: 5px;background-color: rgba(0, 0, 0, 0.01);display: flex;justify-content: space-between;margin-top: 10px;">-->
<!--          <div style="background-color: #d3cacf; padding: 4px;border-radius: 5px;width: 47%;">-->
<!--            <div :style="[{'background-color': info.positive === 'Y' ? 'red' : (info.hsValid === '72' ? '#009c1f' : (info.hsValid === '48' ? '#8658dc' : (info.hsValid === '24' ? '#005ee4' : '#e4e4e4')))}, {'padding': '5px 10px'}, {'border-radius': '5px'}, {'height': '70px'}, {'position': 'relative'}]">-->
<!--              <div style="height: 42px;width: 100%;display: flex;" v-if="info.hsValid && info.hsValid !== ''" class="contentCenter">-->
<!--                <div style="font-size: 40px;color: white;font-weight: 600;line-height: initial">{{ info.hsValid }}</div>-->
<!--                <div style="display: flex;flex-direction: column;margin-left: 5px">-->
<!--                  <div style="font-size: 14px;color: white;font-weight: 600;line-height: initial">小时</div>-->
<!--                  <div style="font-size: 14px;color: white;font-weight: 600;line-height: initial">{{ info.positive === 'Y' ? '阳性' : '阴性' }}</div>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div style="font-size: 26px;color: white;font-weight: 600;" class="contentCenter" v-else>{{ info.positive === 'Y' ? '阳性' : '阴性' }}</div>-->
<!--              <div style="font-size: 12px;color: white;font-weight: 500;text-align: center;position: absolute;bottom: 5px;width: calc(100% - 20px);">{{ info.hsTime || '-' }}</div>-->
<!--            </div>-->
<!--          </div>-->

<!--          <div style="background-color: #d3cacf; padding: 4px;border-radius: 5px;width: 47%;">-->
<!--            <div style="background-color: #e4e4e4; padding: 5px 10px;border-radius: 5px;height: 70px;position: relative;">-->
<!--              <div style="display: flex;position: relative;height: 42px;" class="contentCenter">-->
<!--                <div style="font-size: 19px;color: #28a446;" class="contentCenter" v-if="info.ymNum === '0'">未接种</div>-->
<!--                <div style="font-size: 19px;color: #28a446;" class="contentCenter" v-else-if="info.ymNum === '1'">已接种第1针</div>-->
<!--                <div style="font-size: 19px;color: #28a446;" class="contentCenter" v-else-if="info.ymNum === '2'">已接种第2针</div>-->
<!--                <div style="font-size: 19px;color: #28a446;" class="contentCenter" v-else-if="info.ymNum === '3'">已接种全程</div>-->
<!--                <div style="font-size: 21px;color: #28a446;" class="contentCenter" v-else>-</div>-->
<!--              </div>-->
<!--              <div style="font-size: 12px;color: #28a446;font-weight: 500;text-align: center;position: absolute;bottom: 5px;width: calc(100% - 20px);">{{ info.ymTime || '-' }}</div>-->
<!--            </div>-->
<!--          </div>-->

<!--        </div>-->
<!--        <div style="background-color: rgba(0, 0, 0, 0.1); padding: 5px;border-radius: 5px;margin: 5px">-->
<!--          <div style="background-color: #28a446; padding: 8px 14px;border-radius: 5px;">-->
<!--            <div style="display: flex">-->
<!--              <div>-->
<!--                <img :src="this.publicPath + 'arrow.png'" style="width: 40px;height: 40px;" />-->
<!--              </div>-->
<!--              <div style="padding-left: 20px;">-->
<!--                <div style="color: #fcff00;font-size: 14px" v-if="info.xckStar === 'Y'">行程卡带*号</div>-->
<!--                <div style="color: white;font-size: 14px"><span style="color: #cbf8d6;">行程卡途径地：</span>{{ info.xck }}</div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div style="color: #585858;font-size: 12px;text-align: center;margin-top: 10px">上述信息来自于健康码截图，如有误差请重新上传</div>-->
<!--        <div style="color: #585858;font-size: 12px;text-align: center;margin-top: 4px">更新时间：{{ xckInfoUpdateTime }}</div>-->
<!--      </div>-->
<!--      <div class="reUpload" style="margin-top: 12px;font-size: 12px;text-align: center"-->
<!--           v-if="mode !== 'scanQrcode' && ['学生', '教职工家属', '离退休教职工', '临时人员', '继教学生'].indexOf(info.peopleType) === -1 && (info.canIn !== 'Y' || info.canOut !== 'Y')">-->
<!--        <div>如果您已填写当天健康调查问卷但未开通绿码，请点击以下链接重新上传粤康码、行程卡：</div>-->
<!--        <div style="text-align: center;"><a href="https://wjx.zhbit.com/wjx.html">健康码重新上传</a></div>-->
<!--      </div>-->
    </div>
  </div>
</template>

<script>
import {getInfoByToken, getJssdkConfig, getScanResult, sign} from '../api/manage'
import QRCode from 'qrcodejs2'
import moment from "moment";

// eslint-disable-next-line no-undef
wx.ready(function () {
  // eslint-disable-next-line no-undef
  wx.hideOptionMenu();
});
export default {
  data() {
    return {
      currentTime: new Date(),
      info: {},
      showIn: true,
      isTeacher: false,
      publicPath: process.env.BASE_URL,
      showRelation: false,
      relation: {},
      xckInfoUpdateToday: false,
      xckInfoUpdateTime: null,
      mode: '',
      scanResult: {}
    }
  },
  mounted() {
    let that = this;
    this.timer = setInterval(function () {
      that.currentTime = new Date();
    });
    if (typeof this.$route.query.mode !== 'undefined' && this.$route.query.mode === 'scanQrcode') {
      this.mode = 'scanQrcode'
      getScanResult({ token: this.$route.query.resultToken }).then(res => {
        if (res.code === 200) {
          if (res.qrcodeType === 2) {
            that.handleRoadScanResult(res)
          } else {
            that.scanResult = res
            if (res.direction === 'out') {
              that.showIn = false
            }
          }
        } else {
          that.$error({
            title: '操作失败',
            content: res.msg || '请求出现错误，请重新操作'
          })
        }
      }).catch(err => {
        that.$error({
          title: '操作失败',
          content: err.reason || '请求出现错误，请重新操作'
        })
        console.log(err)
      })
    }
    if (typeof this.$route.query.token !== 'undefined') {
      getInfoByToken({token: this.$route.query.token}).then(res => {
        if (res.code === 200) {
          that.info = res
          that.isTeacher = res.peopleType && res.needShowOutInButtonPeopleTypeList.indexOf(res.peopleType) === -1
          if (res.updateAt) {
            let updateTime = moment(res.updateAt)
            let today = moment()
            that.xckInfoUpdateToday = updateTime.isSame(today, 'day')
            that.xckInfoUpdateTime = updateTime.format("YYYY-MM-DD HH:mm:ss")
          }
          that.creatQrCode(res)
        } else {
          this.$info({
            title: '提示',
            content: "此code已失效，请点击确认按钮刷新页面",
            onOk() {
              window.location.href = "/"
            }
          });
        }
      })
    }
    getJssdkConfig({url: window.location.href}).then(res => {
      // eslint-disable-next-line no-undef
      wx.config({
        beta: true,
        debug: false,
        appId: res.config.appId,
        timestamp: res.config.timestamp,
        nonceStr: res.config.nonceStr,
        signature: res.config.signature,
        jsApiList: ['hideOptionMenu', 'closeWindow', 'scanQRCode']
      })
    })
  },
  beforeDestroy: function () {
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
  computed: {
    handleBg() {
      if (this.mode === 'scanQrcode') {
        return "rgb(226, 226, 226)";
      } else {
        if (this.showIn && this.info.canIn === 'Y' || !this.showIn && this.info.canOut === 'Y') {

          if (this.info.peopleType === '新生') {
            return "#008452";
          } else if (this.info.peopleType === '临时人员' || this.info.peopleType === '校友') {
            return "#0d562a";
          } else if (this.info.peopleType === '家属' || this.info.peopleType === '教职工家属') {
            return "#0191ca";
          }

          return "#003084";
        } else {
          return "#8c1313";
        }
      }
    },
  },
  methods: {
    getQueryString(url, name) {
      var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)')
      var r = url.substr(1).match(reg)
      if (r != null) {
        return unescape(this.delstr(r[2], '#'))
      }
      return null
    },
    delstr(str, c) {
      return str.substring(0, str.indexOf(c))
    },
    handleRoadScanResult (res) {
      let that = this;
      if (res.pass === 'N') {
        that.$error({
          title: '提示',
          content: "暂无" + (this.$route.query.direction === "in" ? "进校" : "出校") + "权限"
        });
      } else {
        if (res.roadResult === 'N') {
          that.$error({
            title: '提示',
            content: "抬杆失败，请重新扫描"
          });
        } else {
          that.$success({
            title: '提示',
            content: "抬杆成功，若车道栏杆未升起，请重新扫码"
          });
        }
      }
    },
    scan() {
      let that = this;
      // eslint-disable-next-line no-undef
      wx.scanQRCode({
        needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
        scanType: ["qrCode"], // 可以指定扫二维码还是一维码，默认二者都有
        success: function (res) {
          let result = res.resultStr; // 当needResult 为 1 时，扫码返回的结果
          let state = that.getQueryString(result, 'state')
          if (!state || that.getQueryString(result, 'state').length !== 16) {
            that.$error({
              title: '操作失败',
              content: '二维码不合法'
            })
            return
          }
          sign({state: that.getQueryString(result, 'state'), token: that.info.signToken}).then(res => {
            if (res.code === 200) {
              if (res.qrcodeType === 1) {
                that.$router.push({path: '/signSuccess', query: {tid: res.tid, time: res.time}})
              } else if (res.qrcodeType === 2) {
                that.handleRoadScanResult(res)
              } else {
                that.scanResult = res
                that.mode = 'scanQrcode'
                if (!that.isTeacher) {
                  if (res.direction)
                    that.showIn = res.direction !== 'out'
                }
              }
            } else {
              that.$error({
                title: '操作失败',
                content: res.msg || '请求出现错误，请稍后再试'
              })
            }
          }).catch(err => {
            that.$error({
              title: '操作失败',
              content: err.reason || '请求出现错误，请稍后再试'
            })
            console.log(err)
          })
        }
      })
    },
    changeUser(e) {
      if (e === 'self') {
        this.showRelation = false
      } else {
        this.showRelation = true
        this.relation = this.info.relationList.filter(o => {
          return o.id === e
        })[0]
      }
    },
    calcTime(time) {
      return !new moment().isBefore(new moment(time))
    },
    changeDirection(e) {
      this.showIn = e
    },
    creatQrCode(res) {
      this.$nextTick(() => {
        new QRCode(this.$refs.qrcode_in, {
          text: res.inQrcode, // 需要转换为二维码的内容
          width: 150,
          height: 150,
          colorDark: res.canIn === 'Y' ? 'rgb(18, 130, 18)' : '#FF0000',
          correctLevel: QRCode.CorrectLevel.H
        })
        new QRCode(this.$refs.qrcode_out, {
          text: res.outQrcode, // 需要转换为二维码的内容
          width: 150,
          height: 150,
          colorDark: res.canOut === 'Y' ? 'rgb(18, 130, 18)' : '#FF0000',
          correctLevel: QRCode.CorrectLevel.H
        })
      })
    },
    dateFormat(time) {
      var date = new Date(time);
      var year = date.getFullYear();
      /* 在日期格式中，月份是从0开始的，因此要加0
       * 使用三元表达式在小于10的前面加0，以达到格式统一  如 09:11:05
       * */
      var month = date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
      var day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      var hours = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
      var minutes = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
      var seconds = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      // 拼接
      return year + "-" + month + "-" + day + " " + hours + ":" + minutes + ":" + seconds;
    }
  }
}
</script>

<style lang="less" scoped>

.headTitleView {
  position: relative;
  overflow: hidden;
  width: 95%;
  margin: 0 auto;

  // 默认
  .normalColor, .relativeColor {
    color: #0690c6;

    &:before, &:after {
      background: #0690c6;
    }
  }

  // 临时人员
  .relative2Color {
    color: #0690c6;

    &:before, &:after {
      background: #0690c6;
    }

    &:before {
      left: -45%!important;
    }

    &:after {
      right: -45%!important;
    }
  }

  .newStudentColor {
    color: #288c44;

    &:before, &:after {
      background: #288c44;
    }
  }

  // 临时人员
  .tempColor {
    color: #0d562a;

    &:before, &:after {
      background: #0d562a;
    }

    &:before {
      left: -40%!important;
    }

    &:after {
      right: -40%!important;
    }
  }

  .headTitle {
    cursor: pointer;
    position: relative;
    font-size: 26px;
    text-align: center;
    font-weight: bold;
    margin-bottom: 10px;

    &:before, &:after {
      position: absolute;
      content: "";
      height: 2px;
      top: 50%;
      width: 68%;
    }

    &:before {
      left: -35%;
    }

    &:after {
      right: -35%;
    }
  }
}

.reUpload {
  padding: 0 5px;
  color: red;
}

.hasExpire {
  color: red;
}

.scanBg{
  background: rgb(226, 226, 226);
}

#tips {
  color: red;
  text-align: center;
  padding-top: 5px;
  font-size: 16px;
  margin-top: 10px;
  font-weight: bold;
}

.allow {
  color: rgb(18, 130, 18);
}

.notAllow {
  color: #FF0000;
}

.notAllowBg {
  background: #8c1313;
}

.allowBg {
  background: #003084;
}

.allowBgExam {
  background: #008452!important;
}

.direction {
  font-weight: bolder;
  font-size: 4rem;
}

.param {
  font-size: 16px;
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  color: gray;
}

.param2 {
  font-size: 14px;
  display: flex;
  margin-top: 6px;
  color: gray;
  padding: 0 5px;
}

.param2:first-child {
  margin-top: 15px;
}

#bg {
  width: 100%;
  min-height: 100%;
  background-size: 100% 100%;
  padding-bottom: 20px;
}

#topH {
  text-align: center;
  background-color: #2c3e50;
  color: white;
  font-size: 1.8rem;
  width: 90%;
  margin-left: 5%;
  border-radius: 5px;
}

#info {
  background-color: white;
  border-radius: 5px;
  box-shadow: 1px 1px 5px gray;
  width: 90%;
  margin-left: 5%;
  margin-top: 1rem;
  padding: 30px 10px;
  z-index: 1000;
  position: relative;
}

.detail-layout {
  margin-left: 44px;
}

.text {
  color: rgba(0, 0, 0, .45);
}

.heading {
  color: rgba(0, 0, 0, .85);
  font-size: 20px;
}

.no-data {
  color: rgba(0, 0, 0, .25);
  text-align: center;
  line-height: 64px;
  font-size: 16px;

  i {
    font-size: 24px;
    margin-right: 16px;
    position: relative;
    top: 3px;
  }
}

.mobile {
  .detail-layout {
    margin-left: unset;
  }

  .text {
  }

  .status-list {
    text-align: left;
  }
}

.else {
  background-color: #13c2c2;
  color: white;
}

.else2 {
  background-color: #1890ff;
  color: white;
}

#particles-js {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1000;
}
/* 居中 */
.contentCenter{
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
