import Vue from 'vue'
import App from './App.vue'
import { Button, Descriptions, Card, Switch, Modal, Form, Table, Input, Radio, Row, InputNumber, Alert, Result, Select } from 'ant-design-vue';
import router from './router'
import VueParticles from 'vue-particles'

Vue.config.productionTip = false

Vue.use(Button)
Vue.use(Descriptions)
Vue.use(Card)
Vue.use(Switch)
Vue.use(Modal)
Vue.use(Form)
Vue.use(Table)
Vue.use(Input)
Vue.use(Radio)
Vue.use(Row)
Vue.use(InputNumber)
Vue.use(Alert)
Vue.use(Result)
Vue.use(Select)
Vue.use(VueParticles)
Vue.config.productionTip = false
Vue.prototype.$info = Modal.info;
Vue.prototype.$error = Modal.error;
Vue.prototype.$success = Modal.success;
Vue.prototype.$form = Form;

router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})

console.log(process.env.NODE_ENV)

new Vue({
  render: h => h(App),
  router
}).$mount('#app')
