<template>
  <a-result
      status="error"
      :title="type ? errorList[type].title : ''"
      :sub-title="type ? errorList[type].subTitle : ''"
  >
    <template #extra>
      <a-button key="console" type="primary" @click="closeW">
        关闭此页面
      </a-button>
    </template>

    <div class="desc">
      <p style="font-size: 16px;">
        <strong>建议:</strong>
      </p>
      <p>
        {{ type ? (errorList[type].suggest || '') : '' }}
      </p>
    </div>
  </a-result>
</template>

<script>
import {getJssdkConfig} from "../api/manage";

// eslint-disable-next-line no-undef
wx.ready(function () {
  // eslint-disable-next-line no-undef
  wx.hideOptionMenu();
});
export default {
  data() {
    return {
      type: null,
      errorList: {
        userNotExist: {
          title: "操作失败",
          subTitle: "系统中未找到你的信息",
          suggest: "请联系管理员"
        },
        systemError: {
          title: "操作失败",
          subTitle: "系统异常",
          suggest: "请联系管理员"
        },
        noAuth: {
          title: "操作失败",
          subTitle: "无权操作"
        },
        tokenExpired: {
          title: "此链接已失效",
          subTitle: "请重新获取"
        },
        codeNotValid: {
          title: "二维码无效",
          subTitle: "此二维码无效，请重新扫描",
          suggest: "请重新扫描"
        },
        canNotGetSignInfo: {
          title: "失败",
          subTitle: "请在电子通行码中扫描",
          suggest: "请使用校园通行码右上角的扫码功能进行签到"
        },
        scanTimeExpire: {
          title: "失败",
          subTitle: "不在可扫时间范围内",
          suggest: "请在可扫时间范围内操作"
        }
      }
    }
  },
  mounted() {
    this.type = this.$route.query.t
    getJssdkConfig({url: window.location.href}).then(res => {
      // eslint-disable-next-line no-undef
      wx.config({
        beta: true,
        debug: false,
        appId: res.config.appId,
        timestamp: res.config.timestamp,
        nonceStr: res.config.nonceStr,
        signature: res.config.signature,
        jsApiList: ['hideOptionMenu', 'closeWindow']
      })
    })
  },
  methods: {
    closeW () {
      // eslint-disable-next-line no-undef
      wx.closeWindow();
    }
  }
}
</script>
<style scoped>
.desc p {
  margin-bottom: 1em;
}
</style>
